<template>
  <div class="uxstep-container" v-if="loadedServiceData">
    <div class="grid">
      <div class="centered-flex">
        <h2 class="title">{{ loadedServiceData.title }}</h2>
        <h3 class="subtitle">{{ loadedServiceData.subtitle }}</h3>
        <p class="description">
          {{ loadedServiceData.description }}
        </p>
        <div class="cta-btn" @click="$store.commit('showLowerPanel', true)">
          <RacButton :text="loadedServiceData.callToAction" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RacButton from "../Atoms/RacButton";
import { SERVICES_QUERY } from "@/gql-operations/allGqlOperations";

export default {
  name: "UxStep",
  components: { RacButton },
  apollo: {
    getData: {
      query: SERVICES_QUERY,
      update(data) {
        const services = data.services.nodes.map((node) => {
          return {
            serviceTitle: node.title,
            ...node.servicesFields.content,
          };
        });
        this.$store.commit("setServices", services);
      },
    },
  },
  created() {
    this.updateCurrentServiceData();
  },
  data() {
    return {
      loadedServiceData: null,
    };
  },
  methods: {
    updateCurrentServiceData() {
      try {
        this.loadedServiceData = this.$store.getters.getService(
          this.currentServiceName
        );
      } catch (e) {
        this.loadedServiceData = null;
      }
    },
  },
  computed: {
    currentServiceName() {
      const serviceName = this.$route.params?.service;
      return serviceName?.replaceAll("_", " ");
    },
  },
  watch: {
    currentServiceName() {
      //waiting for right panel to close
      setTimeout(() => {
        this.updateCurrentServiceData();
      }, 1000);
    },
    "$store.state.services"() {
      this.updateCurrentServiceData();
    },
  },
};
</script>

<style scoped lang="scss">
.uxstep-container {
  .grid {
    @include right-panel-grid;

    .centered-flex {
      grid-column: 2 / span 10;

      .title {
        margin-bottom: 10vh;
      }

      .subtitle {
        color: $orange;
        margin-bottom: 5vh;
      }

      .description {
        white-space: pre-wrap;
        margin-bottom: 5vh;
      }
    }
  }
}

@media screen and (max-width: $small-screen) {
  .uxstep-container {
    .grid {
      .centered-flex {
        grid-column: 1 / span 12;
      }
    }
  }
}
</style>
